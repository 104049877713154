import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  // {
  //   id: "home",
  //   title: "Home",
  //   type: "item",
  //   icon: <Icon.Home size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/"
  // },
  // {
  //   id: "page2",
  //   title: "Page 2",
  //   type: "item",
  //   icon: <Icon.File size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/page2"
  // },
  {
    id: "shop",
    title: "Store",
    type: "item",
    icon: <Icon.ShoppingCart size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },
  {
    id: "howToPlay",
    title: "How to play",
    type: "item",
    icon: <Icon.Info size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/how-to-play",
  },
  {
    id: "subperks",
    title: "Sub Perks",
    type: "item",
    icon: <Icon.Star size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/subperks",
  },
  {
    id: "createACoin",
    title: "Create a coin",
    type: "item",
    icon: <Icon.Info size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/create-a-coin",
  },
  {
    id: "skin",
    title: "My Skins",
    type: "item",
    icon: <Icon.Layers size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/skins",
  },
  {
    id: "downloadPage",
    title: "Download",
    type: "item",
    icon: <Icon.Download size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/downloads",
  },
];

export default navigationConfig;
