import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
import stream from "./stream/index";

const appReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  stream: stream,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZE_STATE":
      // Clear all data in redux store to initial.
      return appReducer(state, action);
    default:
      return appReducer(state, action);
  }
};

export default rootReducer;
