const initialState = {
  categories: [],
  category: "",
  shopitems: [],
  skinList: [],
  activeSkin: "",
  profile: {},
};

const streamReducer = (state = initialState, action) => {
  switch (action.type) {
    case "STREAM_UPDATE_CATEGORIES":
      return {
        ...state,
        categories: action.categories,
      };

    case "STREAM_UPDATE_CURRENT_CATEGORY":
      return {
        ...state,
        category: action.category,
      };
    case "STREAM_UPDATE_SKINLIST":
      return {
        ...state,
        skinList: action.skinList,
      };
    case "STREAM_UPDATE_ACTIVESKIN":
      return {
        ...state,
        activeSkin: action.skin,
      };
    case "STREAM_UPDATE_PROFILE":
      return {
        ...state,
        profile: action.profile,
      };
    case "STREAM_STATE_INIT":
      return initialState;

    default:
      return state;
  }
};

export default streamReducer;
