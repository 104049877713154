import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import axios from "axios";
import * as Icon from "react-feather";
import { connect } from "react-redux";
import {
  loginWithTwitch,
  logoutWithTwitch,
} from "../../../redux/actions/auth/loginActions";

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
  };

  componentDidMount() {
    // axios.get("/api/main-search/data").then(({ data }) => {
    //   this.setState({ suggestions: data.searchResult });
    // });
  }

  render() {
    if (this.props.isAuthenticated) {
      return (
        <ul className="nav navbar-nav navbar-nav-user float-right">
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
              <div className="user-nav d-sm-flex d-none">
                <span className="user-name text-bold-600">
                  {this.props.userName}
                </span>
                <span className="user-status">Available</span>
              </div>
              <span data-tour="user">
                <img
                  src={this.props.userImg}
                  className="round"
                  height="40"
                  width="40"
                  alt="avatar"
                />
              </span>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                tag="a"
                href="#"
                onClick={this.props.logoutWithTwitch}
              >
                <Icon.Power size={14} className="mr-50" />
                <span className="align-middle">Log Out</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ul>
      );
    } else {
      return (
        <a href="#" onClick={this.props.loginWithTwitch}>
          Login
        </a>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.login.status,
  };
};

export default connect(mapStateToProps, { loginWithTwitch, logoutWithTwitch })(
  NavbarUser
);
