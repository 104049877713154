import { values } from "lodash";
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg";

const initialState = {
  userRole: "admin",
  values: {
    name: "Login",
    photoUrl: userImg,
  },
  status: false,
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_FB": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_TWITTER": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_GITHUB": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_JWT": {
      return { ...state, values: action.payload };
    }
    case "LOGOUT_WITH_JWT": {
      return initialState;
    }
    case "LOGIN_WITH_TWITCH": {
      return { ...state, values: action.payload.user, status: true };
    }
    case "LOGOUT_WITH_TWITCH": {
      return { ...state, values: action.payload, status: false };
    }
    case "UPDATE_TWITCH_TOKEN": {
      return {
        ...state,
        values: { ...state.values, token: action.token },
      };
    }
    case "LOGOUT_WITH_FIREBASE": {
      return { ...state, values: action.payload };
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole };
    }
    default: {
      return state;
    }
  }
};
