import axios from "axios";

export const changeRole = (role) => {
  return (dispatch) => dispatch({ type: "CHANGE_ROLE", userRole: role });
};

const TWITCH_CLIENT_ID = process.env.REACT_APP_TWITCH_CLIENT_ID;
const CALLBACK_URL = process.env.REACT_APP_CALLBACK_URL;

const API_END_POINT = `https://id.twitch.tv/oauth2/authorize?`;
const API_END_POINT_USER = `https://api.twitch.tv/kraken/user`;
const API_END_POINT_VALIDATE = `https://id.twitch.tv/oauth2/validate`;

export const loginWithTwitch = () => {
  window.location.href = `${API_END_POINT}response_type=token&client_id=${TWITCH_CLIENT_ID}&scope=user_read&force_verify=true&redirect_uri=${CALLBACK_URL}`;
};

export const logoutWithTwitch = (dispatch) => {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT_WITH_TWITCH",
      payload: {},
    });

    dispatch({
      type: "STREAM_STATE_INIT",
      stream: {},
    });
  };
};

export const authorizeWithTwitch = (access_token) => {
  return axios
    .get(API_END_POINT_USER, {
      headers: {
        "Client-ID": TWITCH_CLIENT_ID,
        Authorization: "OAuth " + access_token,
        Accept: "application/vnd.twitchtv.v5+json",
      },
    })
    .then((profile) => {
      const user = Object.assign({
        userID: profile.data._id,
        name: profile.data.name,
        email: profile.data.email,
        photoUrl: profile.data.logo,
        token: access_token,
      });

      return user;
    })
    .catch((error) => console.log("error:", error.response));
};

export const validateTwitchToken = (access_token) => {
  return axios
    .get(API_END_POINT_VALIDATE, {
      headers: {
        Authorization: "OAuth " + access_token,
        Accept: "application/vnd.twitchtv.v5+json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
